<template>
    <div style="max-width:700px; margin:auto">
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-tabs centered color="white" >
                            <v-tab>Swap</v-tab>
                            <v-tab>Deposit</v-tab>
                            <v-tab>Withdraw</v-tab>
                            <v-tab-item>
                                <v-sheet rounded="sm" style="background-color:#00000050" >
                                    <app-swap-clone @selectedOut="(e) => {selected_pair = e.pair}"></app-swap-clone>
                                    <pre-ape-checklist v-if="selected_pair" :pairId="selected_pair.id"></pre-ape-checklist>
                                </v-sheet>
                            </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AppSwapClone from './app_swap/app-swap-clone.vue'
import PreApeChecklist from './pre-ape-checklist.vue'
export default {
    name: 'view-swap',
    components:{
        AppSwapClone,
        PreApeChecklist
    },
    emits:[
    ],
    data(){return {
        selected_pair:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>