<template>
    <div>
        <v-container fluid>
            <v-row>

                <v-col sm="12" md="3" offset-md="9"  >
                    <div class="swap-menu-icons">
                        <v-icon class="mr-2">mdi-cog</v-icon>
                        <v-icon class="mr-2">mdi-wallet</v-icon>
                    </div>
                </v-col>

            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <div class="form-swap-section">

                        <div>
                            <v-container fluid>
                                <v-row class="mt-1">
                                    <v-col cols="8" md="8">
                                        <div>
                                            <small>You Pay</small>
                                        </div>
                                        <div>
                                            <input class="amt-input" type="text" @change="update_rcv_amt_from_pay_amt()" v-model="pay_pair.amount"  >
                                        </div>
                                    </v-col>
                                    <v-col cols="4" md="4">
                                        <app-swap-token-selector style="float:right" @selected_token="(t)=>{pay_pair = t}" :preselectPair="pay_pair.pair"> </app-swap-token-selector>
                                    </v-col>
                                    <v-col sm="6" md="6">
                                        <div class="subrow-details ml-1"> $ {{(pay_pair.amount*2600).toFixed(2)}} </div>
                                    </v-col>
                                    <v-col sm="6" md="6">
                                        <div style="float:right" class="subrow-details mr-1" @click="pay_pair.amount=pay_pair.balance"> Balance: {{pay_pair.balance}} </div>
                                    </v-col>
                                </v-row>
                            </v-container>

                        </div>
                        <div style="position:absolute; margin:-14px;right:calc(50% - 20px);">
                            <v-btn class="ma-0"  elevation="2" small @click="0" >
                                <v-icon class="ma-0">mdi-arrow-down</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="form-swap-section">
                        <v-container fluid>
                            <v-row class="mt-1">
                                <v-col cols="8" md="8">
                                        <div>
                                            <small>You Receive</small>
                                        </div>
                                        <div>
                                            <input disabled class="amt-input" type="text" @change="0" v-model="receive_pair.amount"  >
                                        </div>
                                </v-col>
                                <v-col cols="4" md="4">
                                    <app-swap-token-selector style="float:right" @selected_token="(t)=>{receive_pair = t}" :preselectPair="receive_pair.pair"  ></app-swap-token-selector>
                                </v-col>
                                <v-col sm="12" md="6">
                                    <div class="subrow-details ml-1" v-if="receive_pair.price"> $ {{(receive_pair.amount*receive_pair.price.price*2600).toFixed(2)}} </div>
                                </v-col>
                                <v-col sm="12" md="6">
                                    <div style="float:right" class="subrow-details mr-1"> Balance: 0.0 </div>
                                </v-col>
                            </v-row>
                    
                        </v-container>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="12">
                    <!-- {{pay_pair}}
                    <hr>
                    {{receive_pair}} -->

                    <v-btn class="ma-0"  elevation="2"   rounded block @click="buy_popup=true" > Buy and send to agent</v-btn>
                </v-col>

            </v-row>
        </v-container>
       



        <v-dialog v-model="buy_popup"  max-width="70vw" >
            <v-sheet rounded="sm">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h4>Confirm</h4>
                            Swap {{pay_pair}} Weth  for {{receive_pair}} Token
                            
                        </v-col>
                        <v-col cols="12" md="12">
                            <team-wallet-selector></team-wallet-selector>
                        </v-col>
                        <v-col sm="12" md="12">
                            <v-btn class="ma-0"  elevation="2" rounded light block @click="submit_swap" >swap</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'

import TeamWalletSelector from '@/components/team-wallet-selector.vue'
import AppSwapTokenSelector from '@/components/app_swap/app-swap-token-selector.vue'
export default {
    name: 'app-swap-clone',
    components:{

        TeamWalletSelector,
        AppSwapTokenSelector,
    },
    emits:[
        'selectedOut'
    ],
    data(){return {
        pay_pair: {
            pair:{
                id:322,
                symbol:'WETH',
                name:'Wrapped Eth'
            },
            price:{

            },
            amount:0,
            balance:0,
        },
        receive_pair:{
            pair:undefined,
            price:undefined,
            amount:0,
            balance:0
        }, 

        buy_popup:false,
        selected_receive_pair:undefined,
        selected_pay_pair: {  id:322, name:'Wrapped Eth', symbol:'WETH' }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        const urlParams = new URLSearchParams(window.location.search);

        const token_in_id = urlParams.get('token_in');
        const token_out_id = urlParams.get('token_out');
        const token_in_amt = urlParams.get('token_in_amt');
        const token_out_amt = urlParams.get('token_out_amt');

        if (token_out_id){
            this.receive_pair.pair = { 
                id: token_out_id
            }
        }

    
    },
    methods:{
        ...mapActions([]),
        update_rcv_amt_from_pay_amt(){
            this.receive_pair.amount = (this.pay_pair.price.price / this.receive_pair.price.price ) * this.pay_pair.amount

        },
        submit_swap(){

        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        receive_pair(nv,ov){
            this.$emit('selectedOut',nv)
        }

    },
}
</script>
<style scoped lang="scss">
.form-swap-section{
    background: rgb(22 22 22 / 48%);
    border-radius: 16px;
    margin:3px;
}
.subrow-details{

    color:gray;
}

.swap-menu-icons{
    display:flex;
    justify-content: flex-end;
}
.amt-input{
    color:white;
    font-size: 2em;
}
</style>